


















import {computed, defineComponent, ref} from '@vue/composition-api';
import {inject} from '@/container';
import {UseUserCourses} from '@/courses/composables/UseUserCourses';
import Assignment from '@/assignments/models/Assignment';
import moment from 'moment';
import {DrawerCoursesService} from '@/drawer/services/DrawerCoursesService';
import {ErrorReportingService} from '@/errors/services/error-reporting';
import SuccessSnackbarPlugin from '@/common/plugins/success-snackbar';
import {UseAllInstitutions} from '@/institutions/composables/UseAllInstitutions';

export default defineComponent({
  name: 'CourseAssignmentsCopy',
  setup() {
    const {courses} = inject(UseUserCourses);
    const {sortCourses} = inject(DrawerCoursesService);
    const errorReportingService = inject(ErrorReportingService);
    const {institutions, fetchInstitutions} = inject(UseAllInstitutions);
    fetchInstitutions();

    const courseItems = computed(() => {
      return sortCourses(courses.value).map((c) => {
        const institution = institutions.value.find((i) => i.id === c.institutionId);

        const date = moment(c.startDate).format('MMM YYYY');
        return {
          text: `${institution?.abbreviation} ${c.courseCode} (${date})`,
          value: c.id,
        };
      });
    });

    const copyFrom = ref(0);
    const copyTo = ref(0);

    const isLoading = ref(false);

    async function copyAssignments() {
      if (copyFrom.value === 0 || copyTo.value === 0) {
        return;
      }
      isLoading.value = true;
      return Assignment.api
        .copyForCourse(copyFrom.value, {
          newOwnerId: copyTo.value,
        })
        .catch(errorReportingService.errorDialogHandler)
        .finally(() => {
          isLoading.value = false;
          (SuccessSnackbarPlugin as any).snackbar.open({
            message: 'Courses have been successfully copied',
            showButton: true,
          });
        });
    }

    document.title = `Copy Course Assignments | Stemble`;

    return {courseItems, copyFrom, copyTo, isLoading, copyAssignments};
  },
});
