import {makeDependency} from '@/container';
import {Course} from '@/courses/models';
import {computed, Ref} from '@vue/composition-api';
import {AuthService, useAuthService} from '@/auth/services/authService';

export const UseUserCourses = makeDependency(() => makeUseUserCourses(useAuthService()));

export function makeUseUserCourses($auth: AuthService) {
  const courses: Ref<Course[]> = computed(() =>
    Course.queryCoursesForUser($auth.user.id, true).all()
  );

  return {
    courses,
  };
}
